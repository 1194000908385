import React from 'react'
import { Link } from 'react-router-dom'
import {AiOutlineEye} from 'react-icons/ai'

function NewsArchiveTable({data}) {
  return (
    
<div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-8">
<table id='transactionsTable' class="w-full text-sm text-left text-gray-500 border-t-2">
    <thead class="text-xs text-gray-700 uppercase bg-gray-50">
        <tr>
            <th scope="col" class="px-6 py-3">
             Date
            </th>
            <th scope="col" class="px-6 py-3">
                Title
            </th>
            <th scope="col" class="px-6 py-3">
                Author
            </th>
            <th scope="col" class="px-6 py-3">
                Categories
            </th>
        </tr>
    </thead>  
    <tbody>
    {data?.map(dt => (
        <tr>
            <td className="px-6 py-3">
            {new Date( Date.parse(dt?.datePublished) ).toLocaleString('en-us', {
                year: '2-digit',
                month: 'short',
                day: '2-digit',
                weekday: 'short',
              })}
            </td>
            <td scope="row" className="px-6 py-4 font-medium text-gray-900 break-words">
                <Link to={`/archives/${dt?.id}`} class="font-medium text-blue-600 dark:text-blue-500">
                    {dt?.title}
                </Link>
            </td>
            <td className="px-6 py-4">
                {dt?.articleAuthor[0].fullName}
            </td>
            <td className="px-6 py-4">
                {JSON.stringify(dt?.categories)}
            </td>
        </tr>
    ))}
    </tbody>
</table>
</div>

  )
}

export default NewsArchiveTable;