import React from 'react'
import { Link } from 'react-router-dom'

function DashboardPage() {
  return (
    <>
        <h1 className='text-2xl font-semibold text-center mt-8 mb-10'>ViDE Admin</h1>

        <div className="grid grid-cols-1 gap-6 mb-6 lg:grid-cols-3">
            <div className="w-full px-4 py-5 bg-white rounded-lg shadow">
                <Link to={"/transactions"}  className="text-sm font-medium text-gray-500 truncate">
                    View Transactions
                </Link>
            </div>
            <div className="w-full px-4 py-5 bg-white rounded-lg shadow">
            <Link to={"/archives"}  className="text-sm font-medium text-gray-500 truncate">
                    Manage News Archives
                </Link>
            </div>
        </div>
    </>
  )
}

export default DashboardPage