import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import NavigationMenu from './components/NavigationMenu';
import { useAuthContext } from './contexts/AuthContext';
import DashboardPage from './pages/DashboardPage';
import LoginPage from './pages/LoginPage';
import Transaction from './pages/Transaction';
import Transactions from './pages/Transactions';
import Archives from './pages/ArchivesPage';
import { ArchiveViewPage } from './pages/ArchiveViewPage';


const AuthRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="*" element={<Navigate to='/'/>} />
      <Route path="/" element={<LoginPage />} />
    </Routes>
  </BrowserRouter>
)

const DashboardRoutes = () => (
  <BrowserRouter>
    <NavigationMenu>
      <Routes>
        <Route path="*" element={<DashboardPage />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/transactions/:id" element={<Transaction />} />
        <Route path="/archives" element={<Archives />} />
        <Route path="/archives/:id" element={<ArchiveViewPage />} />
      </Routes>
    </NavigationMenu>
  </BrowserRouter>
)

function App(){
  const {getAccountData} = useAuthContext();
  return getAccountData() ? <DashboardRoutes /> : <AuthRoutes />;
}

export default App;
