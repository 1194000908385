import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import ErrorAlert from '../components/ErrorAlert';
import Loader from '../components/Loader';
import DOMPurify from 'dompurify';
import ButtonAction from '../components/ButtonAction';

export function ArchiveViewPage() {
let path = useLocation().pathname;
  let transactionId = path.split('/').pop();

  const [transactionData, setTransactionData] = useState(null);

  const [loading,setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios.get(`https://web-archives.newvisionapp.com/V1/Articles/Id?Id=${transactionId}&includeDeleted=true`)
      .then(res => {
        const data = res.data;
        console.log(data);
        setTransactionData(data);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        setError(err);
      })
  },[])

  const handlePublish = () =>{

  }

  return (
    <div>
      {error && <ErrorAlert message={error.message} />}
      {loading && <Loader />}
        {!loading && !error &&
        <div className='space-y-4'>
        <div className='py-4 flex flex-row gap-4 items-center'>
          <ButtonAction
          className="max-w-fit"
          onClick={handlePublish}
          text={transactionData?.status == "published" ? "UN PUBLISH" : "PUBLISH"}
          />
        </div>
        <span className='text-2xl font-bold underline'>{transactionData?.title} </span>
        <p className='italic '>{transactionData?.summary}</p>
        <p>Date Published :{transactionData?.datePublished}</p>
        <p>Status :{transactionData?.status}</p>
        <p>
            <img className='w-32' src={transactionData?.articleAuthor[0].profileImageUrl} />
            {transactionData?.articleAuthor[0].fullName}
        </p>
        <p>{JSON.stringify(transactionData?.categories)}</p>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(transactionData?.content) }} />
        </div>
        
        }
    </div>
  )
}
