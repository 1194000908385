import { useState } from "react";
import logo from '../assets/logo.png'
import { useAuthContext } from "../contexts/AuthContext";
import {FiHome} from 'react-icons/fi';
import {RiMoneyDollarBoxLine} from 'react-icons/ri';
import {MdStorage} from 'react-icons/md';
import { Link } from "react-router-dom";

function NavigationMenu({children}) {
    const [open, setOpen] = useState(false);
    const {logOut} = useAuthContext();

    return (
        <div className="flex">
            <div
                className={` ${
                    open ? "w-40" : "w-60 "
                } flex flex-col h-screen p-3 bg-black shadow duration-300`}
            >
                <div className="space-y-8">
                <div className="flex flex-col">
                    <img className="w-10" src={logo} />
                    <div className="flex items-center justify-between">
                        <h2 className="text-xl font-bold text-white">
                            Dashboard
                        </h2>
                        <button onClick={() => setOpen(!open)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 text-white"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M4 6h16M4 12h8m-8 6h16"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                    
                    <div className="flex-1">
                        <ul className="pt-2 pb-4 space-y-1 text-sm">
                            <li className="rounded-sm">
                                <Link
                                    to={"/"}
                                    className="flex items-center p-2 space-x-3 rounded-md"
                                >
                                    <FiHome className="text-white text-lg" />
                                    <span className="text-gray-100">Home</span>
                                </Link>
                            </li>

                            <li className="rounded-sm">
                                <Link
                                    to={"/transactions"}
                                    className="flex items-center p-2 space-x-3 rounded-md"
                                >
                                    <RiMoneyDollarBoxLine className="text-white text-lg" />
                                    <span className="text-gray-100">Transactions</span>
                                </Link>
                            </li>

                            <li className="rounded-sm">
                                <Link
                                    to={"/archives"}
                                    className="flex items-center p-2 space-x-3 rounded-md"
                                >
                                    <MdStorage className="text-white text-lg" />
                                    <span className="text-gray-100">News Archives</span>
                                </Link>
                            </li>

                            <li className="rounded-sm">
                                <a
                                    onClick={() => logOut()}
                                    className="flex items-center p-2 space-x-3 rounded-md cursor-pointer"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6 text-gray-100"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                                        />
                                    </svg>
                                    <span onClick={() => logOut()} className="text-gray-100">
                                        Logout
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container mx-auto mt-12 p-10">
                {children}
            </div>
        </div>
    );
}

export default NavigationMenu;