export default function InputDetailed({required, onChange, type, name, id, className, value, label, checked}) {
    switch(type){
      case 'checkbox':
        return (
          <div className="items-center flex flex-row gap-4">
            <input
            checked={checked}
            required={required}
            onChange={onChange}
            value={value}
            type={type}
            name={name}
            id={id}
            className={className + ` w-5 mt-1 shadow-sm sm:text-sm border-gray-300 rounded-sm `}
            />
            <label htmlFor={id} className="text-sm font-medium text-gray-700 ">
              {label}
            </label>
          </div>
        )
      default:
        return (
          <div>
            <label htmlFor={id} className="block text-xs font-medium text-gray-700 ">
              {label}
            </label>
            <input
            required={required}
            onChange={onChange}
            value={value}
            type={type}
            name={name}
            id={id}
            className={className + ` w-full mt-1 shadow-sm sm:text-sm border-gray-300 rounded-sm`}
            />
          </div>
        )
    }
  }