import React, { useEffect, useState } from 'react'
import TransactionsTable from '../components/TransactionsTable'
import ButtonAction from '../components/ButtonAction';
import InputDetailed from '../components/InputDetailed';
import axios from 'axios';
import Loader from '../components/Loader';
import ErrorAlert from '../components/ErrorAlert';

const GetFromDate = () => {
    var today = new Date();
    today.setDate(today.getDate() - 10);
    let date = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');
    return date;
}

const GetCurrentDate = () => {
    var today = new Date();
    let date = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');
    return date;
}

const GetDateDifference = (dateStr1, dateStr2) => {
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

function Transactions() {
    const [fromDate, setFromDate] = useState(GetFromDate());
    const [toDate, setToDate] = useState(GetCurrentDate());
    const [daysApart, setDaysApart] = useState(0);
    const [transactionData, setTransactionData] = useState(null);
    const [recordCount, setRecordCount] = useState(0);

    const [categoryId, setCategoryId] = useState(null);
    const [userName, setUserName] = useState(null);
    const [transactionStatus, setTransactionStatus] = useState(-1);

    const [loading,setLoading] = useState(false);
  const [error, setError] = useState(null);

    const handleChangeFromDate = (e) => setFromDate(e.target.value);
    const handleChangeToDate = (e) => setToDate(e.target.value);

    const handleFilteredResults = () => {
      setLoading(true);
      let diffDays = GetDateDifference(fromDate, toDate);
      setDaysApart(diffDays);

      console.log(fromDate);
      console.log(toDate);

      axios.get(`https://transactions-api-production.newvisionapp.com/V1/Reports/transactions/filter?currentDate=${fromDate}&previousDate=${toDate}&productDesc=${categoryId}&userName=${userName}&transactionStatus=${transactionStatus}`)
      .then(res => {
        const data = res.data;
        console.log(data);
        setRecordCount(data.count);
        setTransactionData(data.data);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        setError(err)
      })
  }

  useEffect(() => {
    handleFilteredResults();
  },[])

  const handleExportCSV = () => {
    const table = document.getElementById("transactionsTable");
    const csvContent = convertTableToCSV(table);
    const csvData = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement('a');
    link.setAttribute('href', csvData);
    link.setAttribute('download', 'transactions.csv');
    link.click();
  };

  const handleCategoryChange = (event) => {
    setCategoryId(event.target.value);
  };

  const handleTranactionStatus = (event) => {
    setTransactionStatus(event.target.value);
  };

  const handleUserNameInput = (event) =>{
    setUserName(event.target.value);
  }

  const convertTableToCSV = (table) => {
    const headerRow = Array.from(table.getElementsByTagName('th'));
    const headerValues = headerRow.map((header) => `"${header.innerText}"`);

    const bodyRows = Array.from(table.getElementsByTagName('tr'));
    const lines = bodyRows.map((row) => {
      const cells = Array.from(row.getElementsByTagName('td'));
      const values = cells.map((cell) => `"${cell.innerText}"`);
      return values.join(',');
    });

    return [headerValues.join(','), ...lines].join('\n');
  };

  return (
    <div>
        <p className='text-2xl font-bold'>Transactions</p>
        
        <div className='grid grid-flow-col gap-4'>
          <div className='my-4'>
            <p className='font-bold'>Date</p>
            <div className='flex flex-row gap-4 items-end mt-2'>
                <div className='w-40'>
                    <InputDetailed
                    label="From :"
                    onChange={handleChangeFromDate}
                    required
                    type="date"
                    value={fromDate}
                    name="from-date"
                    id="from-date"/>
                </div>
                <div className='w-40'>
                    <InputDetailed
                    label="To :"
                    onChange={handleChangeToDate}
                    required
                    type="date"
                    value={toDate}
                    name="to-date"
                    id="to-date"/>
                </div>
            </div>
          </div>

          {/* <div className='my-4 space-y-7'>
            <p className='font-bold'>Category</p>
            <select 
             name="product"
             id="product"
             value={categoryId}
             onChange={handleCategoryChange}
            >
              <option value="">ALL</option>
              <option value="TICKETS">Tickets</option>
            </select>
          </div> */}

          <div className='my-4 space-y-7'>
            <p className='font-bold'>Product Desc. (eg. Ticket, Mtn Airtime)</p>
            <input type='text' value={categoryId} onChange={handleCategoryChange} />
          </div>

          <div className='my-4 space-y-7'>
            <p className='font-bold'>Customer Name / Email</p>
            <input type='text' value={userName} onChange={handleUserNameInput} />
          </div>

          <div className='my-4 space-y-7'>
            <p className='font-bold'>Transaction Status</p>
            <select 
             name="transactionStatus"
             id="transactionStatus"
             value={transactionStatus}
             onChange={handleTranactionStatus}
            >
              <option value="-1">ALL</option>
              <option value="0">SUCCESSFUL</option>
              <option value="100">PENDING</option>
              <option value="111">FAILED</option>
            </select>
          </div>
        </div>
        
        <div className='mt-2'>
          <ButtonAction
          className="w-40"
          onClick={handleFilteredResults}
          text={"Filter Results"}
          />
          <p className='pt-2'>Showing results for <strong>{daysApart}</strong> days | <strong>{recordCount}</strong> records</p>
        </div>

        <div className='py-4 flex flex-row gap-4 items-center'>
          <ButtonAction
          className="max-w-fit"
          onClick={handleExportCSV}
          text={"Export CSV Results"}
          />
        </div>

        
        {error && <ErrorAlert message={error.message} />}
        {loading && <Loader />}
        {!loading && !error && <TransactionsTable data={transactionData} /> }
    </div>
  )
}

export default Transactions