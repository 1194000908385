import React, { useEffect, useState } from 'react'
import NewsArchiveTable from '../components/NewsArchiveTable'
import ButtonAction from '../components/ButtonAction';
import InputDetailed from '../components/InputDetailed';
import axios from 'axios';
import Loader from '../components/Loader';
import ErrorAlert from '../components/ErrorAlert';

const GetFromDate = () => {
    var today = new Date("2020-12-12");
    let date = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');
    return date;
}

const GetCurrentDate = () => {
    var today = new Date("2021-01-01");
    let date = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');
    return date;
}

const GetDateDifference = (dateStr1, dateStr2) => {
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

function Archives() {
    const [fromDate, setFromDate] = useState(GetFromDate());
    const [toDate, setToDate] = useState(GetCurrentDate());
    const [transactionData, setTransactionData] = useState(null);
    const [categories, setCategories] = useState([]);
    const [publishStatus, setPublishStatus] = useState(true);

    const [categoryId, setCategoryId] = useState(null);
    const [query, setQuery] = useState(null);
    const [transactionStatus, setTransactionStatus] = useState(-1);

    const [loading,setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleChangeFromDate = (e) => setFromDate(e.target.value);
    const handleChangeToDate = (e) => setToDate(e.target.value);

    const handleDefaultSearch = () => {
      searchDate();
  }

  const getCategories = () => {
    axios.get(`https://web-archives.newvisionapp.com/V1/Articles/available_categories`)
      .then(res => {
        const data = res.data.data;
        console.log(data);
        setCategories(data);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        setError(err)
      })
  }

  const searchAll = () => {
    setLoading(true);
    axios.get(`https://web-archives.newvisionapp.com/V1/Articles/search?query=${query}&offset=0&limit=10&includeDeleted=true`)
    .then(res => {
        const data = res.data;
        console.log(data);
        setTransactionData(data.data);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        setError(err)
      })
  }

  const searchDate = () => {
    setLoading(true);
    console.log(fromDate);
    console.log(toDate);

    axios.get(`https://web-archives.newvisionapp.com/V1/Articles/date_range?currentDate=${toDate}&previousDate=${fromDate}&offset=0&limit=20&includeDeleted=true`)
    .then(res => {
        const data = res.data;
        console.log(data);
        setTransactionData(data.data);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        setTransactionData(null);
        setError(err)
      })
  }

  const searchCategory = () => {
    setLoading(true);
    axios.get(`https://web-archives.newvisionapp.com/V1/Articles/categories?category=${categoryId}&offset=0&limit=10&includeDeleted=true`)
    .then(res => {
        const data = res.data;
        console.log(data);
        setTransactionData(data.data);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        setTransactionData(null);
        setError(err)
      })
  }

  const getByPublisheStatus = () => {
    setLoading(true);
    axios.get(`https://web-archives.newvisionapp.com/V1/Articles/publish-status?isDeleted=${publishStatus}&offset=0&limit=100&includeDeleted=true`)
    .then(res => {
        const data = res.data;
        console.log(data);
        setTransactionData(data.data);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        setTransactionData(null);
        setError(err)
      })
  }

  useEffect(() => {
    setLoading(true);
    handleDefaultSearch();
    getCategories();
  },[])

  const handleCategoryChange = (event) => {
    setCategoryId(event.target.value);
  };

  const handlePublishStatusChange = (event) => {
    setPublishStatus(event.target.value);
  };

  const handleSearchInput = (event) =>{
    setQuery(event.target.value);
  }


  return (
    <div>
        <p className='text-2xl font-bold'>Archives (2000-10-04 - 2021-05-13)</p>
        {/* "minDate": "2000-10-04 00:00:00",
  "maxDate": "2021-05-13 15:26:05" */}
            <div className='grid grid-flow-col gap-4'>
                <div className='my-4 space-y-7'>
                <p className='font-bold'>Search</p>
                <input type='text' value={query} onChange={handleSearchInput} />
                <div className='mt-2'>
                <ButtonAction
                    className="w-40"
                    onClick={searchAll}
                    text={"Search Words"}
                    />
                </div>
            </div>

          <div className='my-4'>
            <p className='font-bold'>Date</p>
            <div className='flex flex-row gap-4 items-end mt-2'>
                <div className='w-40'>
                    <InputDetailed
                    label="From :"
                    onChange={handleChangeFromDate}
                    required
                    type="date"
                    value={fromDate}
                    name="from-date"
                    id="from-date"/>
                </div>
                <div className='w-40'>
                    <InputDetailed
                    label="To :"
                    onChange={handleChangeToDate}
                    required
                    type="date"
                    value={toDate}
                    name="to-date"
                    id="to-date"/>
                </div>
            </div>
            <div className='mt-7'>
                <ButtonAction
                className="w-40"
                onClick={searchDate}
                text={"Search Date"}
                />
            </div>
          </div>

          <div className='my-4 space-y-7'>
            <p className='font-bold'>Category</p>
            <select 
             name="product"
             id="product"
             value={categoryId}
             onChange={handleCategoryChange}
            >
              <option value="">ALL</option>
              {categories.map(c => <option value={c}>{c}</option>)}
            </select>
            <div className='mt-2'>
                <ButtonAction
                className="w-40"
                onClick={searchCategory}
                text={"Search Category"}
                />
            </div>
          </div>

          <div className='my-4 space-y-7'>
            <p className='font-bold'>Publish Status</p>
            <select 
             name="publishStatus"
             id="publishStatus"
             value={publishStatus}
             onChange={handlePublishStatusChange}
            >
              <option>ALL</option>
              <option value="false">PUBLISHED</option>
              <option value="true">UN PUBLISHED</option>
            </select>
            <div className='mt-2'>
                <ButtonAction
                className="w-40"
                onClick={getByPublisheStatus}
                text={"Search by Publish Status"}
                />
            </div>
          </div>
        </div>

        
        {error && <ErrorAlert message={error.message} />}
        {loading && <Loader />}
        {!loading && !error && <NewsArchiveTable data={transactionData} /> }
    </div>
  )
}

export default Archives;