import React from 'react'
import { Link } from 'react-router-dom'
import {AiOutlineEye} from 'react-icons/ai'

function TransactionsTable({data}) {
  return (
    
<div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-8">
<table id='transactionsTable' class="w-full text-sm text-left text-gray-500 border-t-2">
    <thead class="text-xs text-gray-700 uppercase bg-gray-50">
        <tr>
            <th scope="col" class="px-6 py-3">
             Date
            </th>
            <th scope="col" class="px-6 py-3">
                Product Desc.
            </th>
            <th scope="col" class="px-6 py-3">
                Name
            </th>
            <th scope="col" class="px-6 py-3">
                Email
            </th>
            <th scope="col" class="px-6 py-3">
                Payment Status
            </th>
            <th scope="col" class="px-6 py-3">
                Order Status
            </th>
            <th scope="col" class="px-6 py-3">
                Amount (UGX)
            </th>
            <th scope="col" class="px-6 py-3">
                Status Message
            </th>
        </tr>
    </thead>  
    <tbody>
    {data?.map(dt => (
        <tr class={`border-b ${dt?.transactionStatus !== 0 ? 'bg-white' : 'bg-blue-200'}`}>
            <td className="px-6 py-3">
            {new Date( Date.parse(dt?.createdOnUTC) ).toLocaleString('en-us', {
                year: '2-digit',
                month: 'short',
                day: '2-digit',
                weekday: 'short',
              })}
            </td>
            <td scope="row" className="px-6 py-4 font-medium text-gray-900 break-words">
                <Link to={`/transactions/${dt?.transactionId}`} class="font-medium text-blue-600 dark:text-blue-500">
                    {dt?.productDescription}
                </Link>
            </td>
            <td className="px-6 py-4">
                {dt?.accountName}
            </td>
            <td className="px-6 py-4">
                {dt?.accountEmail}
            </td>
            <td className="px-6 py-4">
                {dt?.paymentStatusMsg}
            </td>
            <td className="px-6 py-4">
                {dt?.orderStatusMsg}
            </td>
            <td className="px-6 py-4">
                {dt?.amountToCharge?.toLocaleString("en-US")}
            </td>
            <td className="px-6 py-4">
                {dt?.transactionStatusMessage}
            </td>
        </tr>
    ))}
    </tbody>
</table>
</div>

  )
}

export default TransactionsTable