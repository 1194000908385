import axios from 'axios';
import React, { useState } from 'react'
import AuthLayout from '../components/Authlayout';
import ButtonAction from '../components/ButtonAction'
import ErrorAlert from '../components/ErrorAlert';
import Input from '../components/Input';
import Loader from '../components/Loader';
import PageTitle from '../components/PageTitle'
import { useAuthContext } from '../contexts/AuthContext';
import logo from '../assets/logo.png'

function LoginPage() {
    const {setAccountData} = useAuthContext();
    const [errorMessage, setErrorMessage] = useState(null);
    const [formData, setFormData] = useState({});
    const handleChange=(e)=>setFormData({...formData,[e.target.id]:e.target.value});

    const [loading,setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit=(e)=>{
        e.preventDefault();
        setLoading(true);
        axios.post(`https://api.newvisionapp.com/v1/EventTicketManagement/TicketApprover`, { 
            email: formData['email'],
            password: formData['password']
         })
        .then(res => {
            console.log(res);
            if(res.status === 200){
                setAccountData({
                    email: formData['email'],
                    password: formData['password']
                });
                window.location.reload(false);
            }
        })
        .catch(err => {
            console.log(err);
            setErrorMessage("Error logging in");
            setLoading(false);
        })
    }

  return (
    <AuthLayout>
    <div className='flex flex-col justify-center items-center'>
        <img src={logo} className="w-32 p-8" />
        <PageTitle text={'Login to view app transactions'} />
    </div>
        
        {errorMessage && <ErrorAlert message={errorMessage} />}
        {loading && <Loader />}
        {!loading && !error &&
        <form className="mt-8 space-y-6">
            <Input type={'email'} handleChange={handleChange} value={formData['email']} isRequired={true} id="email" labelText="Email" labelFor="email" name="email" placeholder="email@example.com" />
            <Input type={'password'} handleChange={handleChange} value={formData['password']} id="password" labelText="Password" labelFor="password" name="password" placeholder="password" />
            <ButtonAction onClick={handleSubmit} text={'Login'} />
        </form>
        }
    </AuthLayout>
  )
}

export default LoginPage;
