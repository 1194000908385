import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import ErrorAlert from '../components/ErrorAlert';
import Loader from '../components/Loader';

function Transaction() {
  let path = useLocation().pathname;
  let transactionId = path.split('/').pop();

  const [transactionData, setTransactionData] = useState(null);

  const [loading,setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios.get(`https://transactions-api-production.newvisionapp.com/V1/Orders/full-detail/${transactionId}`)
      .then(res => {
        const data = res.data;
        console.log(data);
        setTransactionData(data);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        setError(err);
      })
  },[])

  return (
    <div>
      <span className='text-2xl font-bold'>Transaction Detail </span>
      {error && <ErrorAlert message={error.message} />}
      {loading && <Loader />}
        {!loading && !error &&
        <pre className='mt-8'>
            {JSON.stringify(transactionData, null, 4)}
        </pre>
        }
    </div>
  )
}

export default Transaction